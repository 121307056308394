// hasTransform3d v1.0 21/02/2016
// 235 bytes gzipped (335 bytes uncompressed)
var hasTransform3d=(function(){var c=document,a=c.createElement("p"),b="none";window.getComputedStyle&&(c.body.insertBefore(a,null),void 0!==a.style.transform&&(a.style.transform="translate3d(1px, 1px, 1px)",b=window.getComputedStyle(a).getPropertyValue("transform")),c.body.removeChild(a));return void 0!==b&&0<b.length&&"none"!==b}());

var cards = document.querySelectorAll(".card-flip");

for (var i = 0; i < cards.length; i++) {
  cards[i].addEventListener("click", toggleBio, false);
}

function toggleBio() {

  var open, close, card, front, back;
  
  var target = this;

  [].forEach.call(cards, function(el) {
    if (target !== el) {
      el.classList.remove("is-flipped");
    }
  });

  card = target.parentElement.parentElement.parentElement;
  front = card.firstElementChild.getElementsByTagName("div")[0];
  back = card.firstElementChild.getElementsByTagName("div")[1];

  card.classList.toggle("is-flipped");

  if (card.classList.contains("is-flipped")) {
    
    open = target;
    close = back.getElementsByTagName("button")[0];
    
    back.setAttribute("aria-hidden", "false");
    front.setAttribute("aria-hidden", "true");
    close.removeAttribute("hidden");
    open.setAttribute("hidden", true);
    close.focus();
    
  } else {
    
    close = target;
    open = front.getElementsByTagName("button")[0];
    
    front.setAttribute("aria-hidden", "false");
    back.setAttribute("aria-hidden", "true");
    open.removeAttribute("hidden");
    close.setAttribute("hidden", true);
    open.focus();

  }
}
