/*
|==========================================================================
| WordPress + Foundation
|==========================================================================
|
| These functions make sure WordPress and Foundation play nice together,
| and this is also where we declare functions defined in this folder.
|
|==========================================================================
|
*/

var detectHomepage = '.page-template-template-home';

jQuery(document).ready(function() {

  'use strict';

  // Evolving function for WCAG guidelines
  v8_wcag();
  // apple_device_detect();

  /* Function to detect element existence for clarity */
  function elementExists(element) {

    var elementSection = element;
    var elementdoesExist = document.querySelector(elementSection) !== null;

    if (elementdoesExist) {
      return true;
    } else {
      return false;
    }

  }

  if (elementExists('.modal-summary')) {
    var gf_button = document.getElementById("gform_submit_button_1");

    gf_button.addEventListener('click', function (event) {
      gf_button.classList.add("fade-out");
    });
  }

  if (elementExists('.modal-summary')) {

    var modalSummary = document.querySelector('.modal-summary');

    modalSummary.addEventListener('click', function (event) {
      setTimeout(function() {
        if (elementExists('.gform_confirmation_message')) {
          modalSummary.classList.add('dn');
        }
      }, 2000);
    });

  }

  var addBodyClass = function (modal) {
    if (elementExists('.apple-device')) {
      document.querySelector('.apple-device').classList.add('modal-open');
    } 
  };
  var removeBodyClass = function (modal) {
    if (elementExists('.apple-device')) {
      document.querySelector('.apple-device').classList.remove('modal-open');
    }
  };

  /* Turn on modals */
  if (elementExists('.modal')) {

    MicroModal.init({
      onShow: addBodyClass,
      onClose: removeBodyClass,
      disableScroll: true,
      awaitCloseAnimation: true
    });

  }

const scrollHeader = document.getElementById('scroll-header');

function activate_menu() {
  let scrollTop = window.pageYOffset;

  if (scrollTop >= 108) {
    scrollHeader.classList.remove('dn');
    scrollHeader.classList.add('flex');
    scrollHeader.classList.remove('collapse');
    scrollHeader.classList.add('open');
  } 

  if (scrollTop <= 107) {
    scrollHeader.classList.remove('open');
    scrollHeader.classList.add('collapse');
  }
}

window.addEventListener("scroll", function() {

  activate_menu();

}, {passive: true});

window.addEventListener("DOMContentLoaded", function() {

  activate_menu();

});


  // Detect Internet Explorer
  // detect_IE();

  // Detect Native Android Browser
  // detect_native_android_browser();

  // Apple Device Detect
  // apple_device_detect();

  // Enable AOS for use out of the box
  AOS.init({
    disable: 'mobile'
  });

  /* Media queries to control experience */
  var mediumScreen = window.matchMedia( "(min-width: 40em)" );
  var largeScreen = window.matchMedia( "(min-width: 64em)" );

  /* Helper function for setting multiple attributes */
  function setAttributes(el, attrs) {
    for(var key in attrs) {
      el.setAttribute(key, attrs[key]);
    }
  }

  /* This is the function we use for the magic */
  function animation(element, animation, delay, duration) {
    var a = element;
    var b = a.replace(".", "");

    /* The JS Way */
    // c = document.querySelector('.' + b);
    // c.classList.add('animated', animation);
    // setAttributes(c, {
    //   "animation-delay": delay + "s",
    //   "animation-duration": duration + "s"
    // });

    /* The jQuery way */
    b = jQuery(a);
    b.addClass('animated ' + animation);
    b.css({'animation-delay': delay + 's', 'animation-duration': duration + 's'});
  }

  /* The function we use to enable the counters */
  function startCounters() {
    var options = {
        useEasing: false,
        useGrouping: true,
        separator: ',',
        decimal: '.'
    };

    var board_member_risk = new CountUp('board-member-risk', 53, 73, 0, 1, options);
    var board_member_address = new CountUp('board-member-address', 19, 39, 0, 1.25, options);

    if (!board_member_risk.error) {
      setTimeout(board_member_risk.start, 400);
    } else {
        console.error(board_member_risk.error);
    }

    if (!board_member_address.error) {
        setTimeout(board_member_address.start, 500);
    } else {
        console.error(board_member_address.error);
    }
  }

  /* Only initialize fullpage if not IE and on homepage */
  // if (!detect_IE() && elementExists(detectHomepage)) {
    if (!is_ie_11 && !is_explorer && elementExists(detectHomepage)) {

      (function($) {

        'use strict';

        /* Variables */
        var $isAnimatedFirst = $('.first .is-animated'),
        $isAnimatedSecond = $('.second .is-animated'),
        $isAnimatedSecondSingle = $('.second .is-animated__single'),
        $isAnimatedThird = $('.third .is-animated'),
        $isAnimatedThirdSingle = $('.third .is-animated__single'),
        $isAnimatedFourth = $('.fourth .is-animated'),
        $isAnimatedFourthSingle = $('.fourth .is-animated__single'),
        $placeholder = $('.placeholder');

        /* Initialize fullPage */
        $('#fullpage').fullpage({

          v2compatible: true,
          licenseKey: '11723C06-13E74E4C-A8179E5A-F2963A3B',
          navigation: true,
          navigationPosition: 'left',
          showActiveTooltip: true,
          slidesNavigation: true,
          slidesNavPosition: 'bottom',
          controlArrows: false,
          responsiveWidth: 768,
          paddingTop: '71px',
          anchors: ['first', 'second', 'third', 'fourth', 'fifth', 'press', 'footer'],
          afterLoad: function(anchorLink, index) {
            if (largeScreen.matches) {
              $isAnimatedFirst.eq(0).addClass('animated fadeInUp');
              $isAnimatedFirst.eq(0).css({'animation-delay': '.3s', 'animation-duration': '1s'});
              $isAnimatedFirst.eq(1).addClass('animated fadeInUp');
              $isAnimatedFirst.eq(1).css({'animation-delay': '.4s', 'animation-duration': '1s'});
              $isAnimatedFirst.eq(2).addClass('animated fadeInUp');
              $isAnimatedFirst.eq(2).css({'animation-delay': '.9s', 'animation-duration': '1s'});
            }

          },
          onLeave: function(index, nextIndex, direction) {

            if (largeScreen.matches) {

              /* Remove added classes so animations can happen again */
              $('.animated').removeAttr("style");
              $('.animated').removeClass("animated fadeInUp fadeIn fadeOut fadeInRight fadeInLeft rotateInUpLeft slideInRight");

              var first_section = document.querySelector('.first-section');

              first_section.classList.add('fp-padding-top-fix');

              /* First animation */
              if( nextIndex == 2 ) {

                animation('.reputation-risk', 'fadeInUp', '.3', '1');
                animation('.reputation-risk-copy', 'fadeInUp', '.4', '1');
                animation('.reputation-risk-button', 'fadeInUp', '.9', '1');

                animation('.board-member-risk', 'fadeInRight', '.3', '1.5');
                animation('.board-member-risk-copy', 'fadeIn', '2', '1.5');
                animation('.percent', 'fadeIn', '1.8', '1');

                animation('.board-member-address', 'fadeInRight', '.4', '1.75');
                animation('.board-member-address-copy', 'fadeIn', '2.2', '1.8');
                animation('.percent-2', 'fadeIn', '2.1', '1');

                /* Only enable the counter animation on large screens */
                if (largeScreen.matches) {
                  startCounters();
                }

              }

              /* Second animation */
              else if( nextIndex == 3 ) {
                $isAnimatedThird.eq(0).addClass('animated fadeInUp');
                $isAnimatedThird.eq(0).css({'animation-delay': '.3s', 'animation-duration': '1s'});
                $isAnimatedThird.eq(1).addClass('animated fadeInUp');
                $isAnimatedThird.eq(1).css({'animation-delay': '.4s', 'animation-duration': '1s'});
                $isAnimatedThird.eq(2).addClass('animated fadeInUp');
                $isAnimatedThird.eq(2).css({'animation-delay': '.9s', 'animation-duration': '1s'});

                animation('.pie-rotate', 'rotateInUpLeft', '1.5', '1');
                var pie = $('#pie-cover');
                pie.css({'animation': 'spin 2s 1', 'animation-fill-mode': 'forwards', 'animation-delay': '2.5s'});

                animation('#arrow-triangle', 'fadeInUp', '1.5', '1');
                animation('#arrow-line-1', 'fadeInUp', '1.65', '1');
                animation('#arrow-line-2', 'fadeInUp', '1.75', '1.25');
                animation('#arrow-line-3', 'fadeInUp', '1.85', '1.5');

                var pie_chart = $('#pie-chart');

                animation('.company-perceptions', 'fadeIn', '3', '1');
                var company_perceptions = $('.company-perceptions');
                pie.css({'animation': 'spin 2s 1', 'animation-fill-mode': 'forwards', 'animation-delay': '2s'});
              }

              /* Third Animation */
              else if( nextIndex == 4 ) {
                $isAnimatedFourth.eq(0).addClass('animated fadeInUp');
                $isAnimatedFourth.eq(0).css({'animation-delay': '.3s', 'animation-duration': '1s'});
                $isAnimatedFourth.eq(1).addClass('animated fadeInUp');
                $isAnimatedFourth.eq(1).css({'animation-delay': '.4s', 'animation-duration': '1s'});
                $isAnimatedFourth.eq(2).addClass('animated fadeInUp');
                $isAnimatedFourth.eq(2).css({'animation-delay': '.9s', 'animation-duration': '1s'});

                animation('.success-outcomes', 'slideInRight', '.9', '.9');

                animation('#su-triangle-1', 'fadeIn', '1.4', '1.4');
                animation('#su-triangle-2', 'fadeIn', '1.2', '1.3');
                animation('#su-triangle-3', 'fadeIn', '1.0', '1.2');
                animation('#su-outcomes', 'fadeIn', '1.9', '1.8');
                animation('#su-outputs', 'fadeOut', '1.6', '1.6');
                animation('.success-outcomes-text', 'fadeIn', '1.8', '1.6');

                // animation('.metric-1-mover', 'slideInRight', '.7', '.7');
                // var metric_1 = $('.metric-1');
                // metric_1.css({'animation': 'float 4s ease-in-out infinite alternate forwards running', 'animation-delay': '1s'});

                // animation('.metric-2-mover', 'slideInRight', '.9', '.9');
                // var metric_2 = $('.metric-2');
                // metric_2.css({'animation': 'float 6s ease-in-out infinite alternate forwards running', 'animation-delay': '1s'});

                // animation('.metric-3-mover', 'slideInRight', '1.3', '1.3');
                // var metric_3 = $('.metric-3');
                // metric_3.css({'animation': 'float 5s ease-in-out infinite alternate forwards running', 'animation-delay': '1s'});

                // animation('.metric-4-mover', 'slideInRight', '1.7', '1.7');
                // var metric_4 = $('.metric-4');
                // metric_4.css({'animation': 'float 3s ease-in-out infinite alternate forwards running', 'animation-delay': '1s'});

              }

              /* Fourth Animation */
              else if( nextIndex == 5 ) {

                animation('.testimonial-content', 'fadeInUp', '.3', '1');
                animation('.testimonial-avatar', 'fadeInUp', '.7', '1');
                animation('.testimonial-name', 'fadeInUp', '.9', '1.2');
                animation('.testimonial-details', 'fadeInUp', '1.1', '1.5');
                animation('.testimonial-cta', 'fadeInUp', '1.3', '1.8');
                animation('.testimonial-container', 'fadeInRight', '2', '1');

              }
            }

          }

        });

})(jQuery);
}

var scroll = new SmoothScroll('[data-scroll]', {

  // Selectors
  ignore: '[data-scroll-ignore]', // Selector for links to ignore (must be a valid CSS selector)
  header: '.scroll-header', // Selector for fixed headers (must be a valid CSS selector)
  topOnEmptyHash: true, // Scroll to the top of the page for links with href="#"

  // Speed & Easing
  speed: 1000, // Integer. How fast to complete the scroll in milliseconds
  clip: true, // If true, adjust scroll distance to prevent abrupt stops near the bottom of the page
  easing: 'easeInOutCubic', // Easing pattern to use

  // History
  updateURL: true, // Update the URL on scroll
  popstate: true, // Animate scrolling with the forward/backward browser buttons (requires updateURL to be true)

  // Custom Events
  emitEvents: true // Emit custom events

});



//   var homepage = new fullpage('#fullpage', {
//   //Navigation
//   licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
//   menu: '#menu',
//   lockAnchors: false,
//   anchors:['slide1', 'slide2', 'slide3', 'slide4', 'slide5'],
//   navigation: false,
//   navigationPosition: 'right',
//   navigationTooltips: ['firstSlide', 'secondSlide'],
//   showActiveTooltip: false,
//   slidesNavigation: false,
//   slidesNavPosition: 'bottom',

//   //Scrolling
//   css3: true,
//   scrollingSpeed: 700,
//   autoScrolling: true,
//   fitToSection: true,
//   fitToSectionDelay: 1000,
//   scrollBar: false,
//   easing: 'easeInOutCubic',
//   easingcss3: 'ease',
//   loopBottom: false,
//   loopTop: false,
//   loopHorizontal: true,
//   continuousVertical: false,
//   continuousHorizontal: false,
//   scrollHorizontally: false,
//   interlockedSlides: false,
//   dragAndMove: false,
//   offsetSections: false,
//   resetSliders: false,
//   fadingEffect: false,
//   normalScrollElements: '#footer',
//   scrollOverflow: false,
//   scrollOverflowReset: false,
//   scrollOverflowOptions: null,
//   touchSensitivity: 15,
//   normalScrollElementTouchThreshold: 5,
//   bigSectionsDestination: null,

//   //Accessibility
//   keyboardScrolling: true,
//   animateAnchor: true,
//   recordHistory: true,

//   //Design
//   controlArrows: true,
//   verticalCentered: true,
//   sectionsColor : ['#ccc', '#fff'],
//   // paddingTop: '3em',
//   paddingBottom: '10px',
//   fixedElements: '#header',
//   responsiveWidth: 0,
//   responsiveHeight: 0,
//   responsiveSlides: false,
//   parallax: false,
//   parallaxOptions: {type: 'reveal', percentage: 62, property: 'translate'},

//   //Custom selectors
//   sectionSelector: '.section',
//   slideSelector: '.slide',

//   lazyLoading: false,

//   //events
//   onLeave: function(origin, destination, direction){},
//   afterLoad: function(origin, destination, direction){},
//   afterRender: function(){},
//   afterResize: function(width, height){},
//   afterResponsive: function(isResponsive){},
//   afterSlideLoad: function(section, origin, destination, direction){},
//   onSlideLeave: function(section, origin, destination, direction){}
// });

//   new fullpage('#fullpage', {
//   //options here
//   autoScrolling:true,
//   scrollHorizontally: true
// });

//methods
// fullpage_api.setAllowScrolling(false);

  // Enable skrollr
  // var s = skrollr.init();

  // Gravity Forms Placeholder Clearing
  // gravity_forms_placeholders();

  // Inline SVG replacement
  // inline_svg_replacement();

  // Isotope Masonry
  // isotope_masonry();

  // TablePress Cleanup
  // tablepress();

  // Event Target Testing
  jQuery(document).click(function(event) {

  });

  // Window scroll detection
  jQuery(window).bind("scroll", function(event) {

  });

  // Remove empty P tags created by WP inside of Accordion and Orbit
  jQuery('.accordion p:empty, .orbit p:empty').remove();

  // Makes sure last grid item floats left
  jQuery('.archive-grid .columns').last().addClass( 'end' );

  // Adds Flex Video to YouTube and Vimeo Embeds
  jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
    if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
      jQuery(this).wrap("<div class='widescreen flex-video'/>");
    } else {
      jQuery(this).wrap("<div class='flex-video'/>");
    }
  });

});
