/*
|==========================================================================
| Device Detection Scripts
|==========================================================================
| 
| Various functions for helpful device detection.
|
|==========================================================================
|
*/

var ua = window.navigator.userAgent;

var is_ios = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
var is_webkit = !!ua.match(/WebKit/i);
var is_ios_safari = is_ios && is_webkit && !ua.match(/CriOS/i) && !ua.match(/OPiOS/i);

var is_chrome = window.chrome;
var is_explorer = ua.indexOf('MSIE') > -1;
var is_firefox = ua.indexOf('Firefox') > -1;
var is_safari = ua.indexOf("Safari") > -1;
var is_edge = ua.indexOf("Edge") > -1;
var is_opera = ua.toLowerCase().indexOf("op") > -1;
var is_apple_device = ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0;
var is_native_android = ((ua.indexOf('Mozilla/5.0') > -1 && ua.indexOf('Android ') > -1 && ua.indexOf('AppleWebKit') > -1) && (ua.indexOf('Version') > -1));

var is_ie_10 = navigator.appVersion.indexOf("MSIE 10") !== -1;
var is_ie_11 = ua.indexOf("Trident") !== -1 && ua.indexOf("rv:11") !== -1;

if ((is_chrome)&&(is_safari)) { is_safari = false; }
if ((is_chrome)&&(is_opera)) { is_chrome = false; }

if (is_ie_10) {
  document.documentElement.className += 'ie-10';
} else if (is_ie_11) {
  document.documentElement.className += 'ie-11';
}

if (!is_ie_10) {

  if (is_apple_device) {
    document.body.classList.add('apple-device');
  }

  if ((is_native_android) && (!is_chrome)) {
    document.body.classList.add('native-android-browser');
  }  

  if (is_edge) {
    document.body.classList.add('edge');
  } else if (is_ie_11) {
    document.body.classList.add('ie-11-or-newer');
  } else if (is_chrome) {
    document.body.classList.add('chrome');
  } else if (is_firefox) {
    document.body.classList.add('firefox');
  } else if (is_safari) {
    document.body.classList.add('safari');
  } else if (is_opera) {
    document.body.classList.add('opera');
  } else if (is_explorer) {
    document.body.classList.add('ie');
  }

}